<template>
  <div>
    <template v-if="grid.md">
      <nuxt-link
        v-for="item in items"
        :key="item.route"
        :to="item.route"
        class="text-sm text-gray-600 [&.router-link-active]:text-gray-900 hover:text-gray-900 transition font-medium px-1 relative h-full items-center inline-flex max-w-fit"
      >
        <span>
          {{ item.title }}
        </span>
        <div class="absolute bottom-0 left-0 w-full h-[3px] bg-primary-500 rounded-t hidden [.router-link-active>&]:block" />
      </nuxt-link>
    </template>
    <template v-else>
      <NavigationItem
        v-for="item in items"
        :key="item.id"
        :item="item"
      />
    </template>
  </div>
</template>

<script setup>
const items = computed(() => ([
  useAuthStore().hasRole(['admin', 'support', 'sales', 'developer'])
    ? {
      title: useT('bcd1'),
      route: '/events',
    }
    : undefined,
  useAuthStore().hasRole(['admin', 'support', 'sales', 'developer'])
    ? {
      title: useT('bcd204'),
      route: '/workspaces',
    }
    : undefined,
  useAuthStore().hasRole(['admin', 'sales'])
    ? {
      title: useT('bcd239'),
      route: '/quotes',
    }
    : undefined,
]).filter(Boolean));
</script>
