const getHost = () => {
  const requestEvent = useRequestEvent();
  if (requestEvent) {
    return requestEvent.node.req.headers.host;
  }

  return window.location.host;
};

const isTokenExpired = (token) => {
  try {
    const payload = token.split('.')[1];

    let decodedPayload;
    if (typeof Buffer !== 'undefined') {
      decodedPayload = Buffer.from(payload, 'base64').toString();
    } else {
      decodedPayload = window.atob(payload);
    }

    const { exp } = JSON.parse(decodedPayload);

    return Date.now() >= exp * 1000;
  } catch (err) {
    console.error('Invalid token:', err);
    return true;
  }
};

const request = (method) => {
  return async(url, options) => {
    let token = useCookie('token').value;
    const refresh = useCookie('refresh').value;
    const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';
    const baseURL = `${protocol}://${getHost().replace(useRuntimeConfig().public.port, useRuntimeConfig().public.apiPort)}/api`;

    if (token && refresh && isTokenExpired(token)) {
      const refreshResponse = await $fetch('/public/users/refresh', {
        baseURL,
        method: 'POST',
        body: {
          refresh,
        },
      }).catch(() => false);

      if (refreshResponse) {
        useAuthStore().setAuth(refreshResponse);
        token = useAuthStore().token;
      }
    }

    const requestOptions = {
      method,
      baseURL,
      headers: {
        ...(options?.headers || {}),
        ...(token ? { Authorization: token } : {}),
      },
      ...options,

      onResponseError({ response }) {
        if (response?.status === 401) {
          useAuthStore().logout();
        }
      },
    };

    return $fetch(url, requestOptions);
  };
};

export default {
  delete: request('DELETE'),
  get: request('GET'),
  patch: request('PATCH'),
  post: request('POST'),
  put: request('PUT'),
};
