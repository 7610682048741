<template>
  <Html :lang="$i18n.locale">
    <Body id="app">
      <el-config-provider :locale="en">
        <nuxt-layout>
          <div class="max-w-screen overflow-x-hidden">
            <nuxt-loading-indicator
              color="#000"
              :height="1"
            />
            <div
              v-if="!grid.md"
              class="transition-all min-w-[var(--mobile-navigation-width)] fixed z-[1234] top-0 left-0 -ml-[var(--mobile-navigation-width)] h-full pb-4 px-4 bg-gray-50 border-r"
              :class="{
                'ml-0': !grid.md && useGeneralStore().isBurgerMenuOpen,
              }"
            >
              <div class="h-14 flex items-center mb-1">
                <nuxt-link
                  to="/"
                  class="py-2"
                >
                  <MainLogo
                    class="w-auto h-8"
                  />
                </nuxt-link>
              </div>
              <MainNavigation class="flex flex-col [&_a]:text-xl" />
            </div>
            <main
              class="transition-all"
              :class="{
                'ml-[var(--mobile-navigation-width)] md:ml-0 min-w-max md:min-w-0': !grid.md && useGeneralStore().isBurgerMenuOpen
              }"
              @click="useGeneralStore().isBurgerMenuOpen = false"
            >
              <nuxt-page />
            </main>
          </div>
        </nuxt-layout>
      </el-config-provider>
    </Body>
  </Html>
</template>

<script setup>
import en from 'element-plus/dist/locale/en.mjs';

// Refs
const i18n = useI18n();
const isOnline = useOnline();
const isOfflineToastClose = ref(false);

onMounted(() => {
  useNuxtApp()?.$replay?.start();
});

onBeforeUnmount(() => {
  useNuxtApp()?.$replay?.stop();
});

watch(i18n.locale, () => {
  useDayjs.locale(i18n.locale.value);
  useNuxtApp()?.$sentry?.setTag?.('locale', i18n.locale.value);
}, { immediate: true });

watch(() => useAuthStore().user, (user) => {
  if (user) {
    useNuxtApp()?.$sentry?.setUser({
      id: user?.id,
      email: user?.email,
      username: user?.fullName,
    });
  }
}, { immediate: true });

watch(() => useRoute().path, () => {
  useGeneralStore().isBurgerMenuOpen = false;
}, { immediate: true });

watch(isOnline, () => {
  if (!isOnline.value) {
    const { close } = useToast(useT('xyz107'), {
      duration: 0,
    });
    isOfflineToastClose.value = close;
  } else {
    isOfflineToastClose.value?.();
  }
});
</script>
