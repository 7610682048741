import { default as indexuedj0fLb5OMeta } from "/vercel/path0/pages/events/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as indexKqOPOCje6QMeta } from "/vercel/path0/pages/quotes/index.vue?macro=true";
import { default as indexSFCbSdUFWMMeta } from "/vercel/path0/pages/workspaces/index.vue?macro=true";
export default [
  {
    name: "events",
    path: "/events",
    meta: indexuedj0fLb5OMeta || {},
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "quotes",
    path: "/quotes",
    meta: indexKqOPOCje6QMeta || {},
    component: () => import("/vercel/path0/pages/quotes/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaces",
    path: "/workspaces",
    meta: indexSFCbSdUFWMMeta || {},
    component: () => import("/vercel/path0/pages/workspaces/index.vue").then(m => m.default || m)
  }
]