module.exports = {
  important: '#app',
  content: [
    'components/**/*.{vue,js}',
    'layouts/**/*.vue',
    'pages/**/*.vue',
    'composables/**/*.{js,ts}',
    'plugins/**/*.{js,ts}',
    'stores/**/*.{js,ts}',
    'App.{js,ts,vue}',
    'app.{js,ts,vue}',

    'node_modules/@attendu/design-system/components/**/*.{vue,js}',
    'node_modules/@attendu/design-system/composables/**/*.{js,ts}',
    'node_modules/@attendu/design-system/stores/**/*.{js,ts}',
  ],
  theme: {
    container: false,
    screens: {
      xs: '540px', // @media (min-width: 540px) { ... }
      sm: '640px', // @media (min-width: 640px) { ... }
      md: '768px', // @media (min-width: 768px) { ... }
      lg: '1024px', // @media (min-width: 1024px) { ... }
      xl: '1280px', // @media (min-width: 1280px) { ... }
      '2xl': '1536px', // @media (min-width: 1536px) { ... }
      '3xl': '1920px', // @media (min-width: 1920px) { ... }
    },
    fontSize: {
      '4xl': ['clamp(1.688rem, 1.35rem + 1.13vw, 2.25rem)', { // 27px to 36px
        lineHeight: '1.1',
        letterSpacing: '-0.02em',
      }],
      '3xl': ['clamp(1.5rem, 1.275rem + 0.75vw, 1.875rem)', { // 24px to 30px
        lineHeight: '1.2',
        letterSpacing: '-0.02em',
      }],
      '2xl': ['clamp(1.25rem, 1.1rem + 0.5vw, 1.5rem)', { // 20px to 24px
        lineHeight: '1.35',
        letterSpacing: '-0.01em',
      }],
      xl: ['clamp(1.125rem, 1.05rem + 0.25vw, 1.25rem)', { // 18px to 20px
        lineHeight: '1.4',
        letterSpacing: '0',
      }],
      lg: ['clamp(1rem, 0.925rem + 0.25vw, 1.125rem)', { // 16px to 18px
        lineHeight: '1.5',
        letterSpacing: '0',
      }],
      base: ['1rem', { // 16px
        lineHeight: '1.5',
        letterSpacing: '0',
      }],
      sm: ['0.875rem', { // 14px
        lineHeight: '1.4',
        letterSpacing: '0',
      }],
      xs: ['0.75rem', { // 12px
        lineHeight: '1.3',
        letterSpacing: '0',
      }],

      'page-6xl': ['clamp(2.25rem, 1.725rem + 1.75vw, 3.125rem)', { // 36px to 50px
        lineHeight: '1.2',
      }],
      'page-5xl': ['clamp(1.688rem, 1.35rem + 1.13vw, 2.25rem)', { // 27px to 36px
        lineHeight: '1.1',
      }],
      'page-4xl': ['clamp(1.5rem, 1.275rem + 0.75vw, 1.875rem)', { // 24px to 30px
        lineHeight: '1.2',
      }],
      'page-3xl': ['clamp(1.375rem, 1.15rem + 0.75vw, 1.75rem)', { // 22px to 28px
        lineHeight: '1.22',
      }],
      'page-2xl': ['clamp(1.25rem, 1.1rem + 0.5vw, 1.5rem)', { // 20px to 24px
        lineHeight: '1.35',
      }],
      'page-xl': ['clamp(1.125rem, 1.05rem + 0.25vw, 1.25rem)', { // 18px to 20px
        lineHeight: '1.4',
      }],
      'page-lg': ['1.125rem', { // 18px
        lineHeight: '1.5',
      }],
      'page-base': ['1rem', { // 16px
        lineHeight: '1.5',
      }],
      'page-sm': ['0.875rem', { // 14px
        lineHeight: '1.4',
      }],
      'page-xs': ['0.75rem', { // 12px
        lineHeight: '1.3',
      }],
    },
    boxShadow: {
      none: 'none',
      sm: '0px 1px 0px rgba(0, 0, 0, 0.06), inset 0px 1px 0px rgba(255, 255, 255, 0.03)',
      DEFAULT: '0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
      md: '0px 3px 6px -3px rgba(0, 0, 0, 0.06), 0px 8px 20px -4px rgba(0, 0, 0, 0.06)',
      lg: '0px 12px 16px -4px rgba(0, 0, 0, 0.08), 0px 4px 6px -2px rgba(0, 0, 0, 0.03)',
    },
    transitionDuration: {
      DEFAULT: '300ms',
      75: '75ms',
      100: '100ms',
      150: '150ms',
      200: '200ms',
      300: '300ms',
      400: '400ms',
      500: '500ms',
      700: '700ms',
      1000: '1000ms',
    },
    extend: {
      screens: {
        'hover-none': { raw: '(hover: none)' },
      },
      colors: {
        primary: {
          900: 'var(--color-primary-900, #004800)',
          800: 'var(--color-primary-800, #005C00)',
          700: 'var(--color-primary-700, #007108)',
          600: 'var(--color-primary-600, #008621)',
          500: 'var(--color-primary-500, #19A43A)',
          '500/15': 'var(--color-primary-500-15, rgb(25, 164, 58, .15))',
          400: 'var(--color-primary-400, #3ACA51)',
          300: 'var(--color-primary-300, #65EE72)',
          200: 'var(--color-primary-200, #B2F9B5)',
          100: 'var(--color-primary-100, #E7FCE8)',
          50: 'var(--color-primary-50, #F1FFF1)',
        },
        gray: {
          900: '#24292E',
          800: '#2F363D',
          700: '#444D56',
          600: '#586069',
          500: '#6A737D',
          400: '#959DA5',
          300: '#D0D7DE',
          200: '#EDF0F2',
          100: '#F3F5F6',
          50: '#FAFBFC',
        },
        blue: {
          900: '#05264C',
          800: '#032F62',
          700: '#044289',
          600: '#005CC5',
          500: '#0366D6',
          400: '#2188FF',
          300: '#79B8FF',
          200: '#C8E1FF',
          100: '#DBEDFF',
          50: '#F1F8FF',
        },
        green: {
          900: '#144620',
          800: '#165C26',
          700: '#176F2C',
          600: '#22863A',
          500: '#28A745',
          400: '#34D058',
          300: '#85E89D',
          200: '#BEF5CB',
          100: '#DCFFE4',
          50: '#F0FFF4',
        },
        red: {
          900: '#86181D',
          800: '#9E1C23',
          700: '#B31D28',
          600: '#CB2431',
          500: '#D73A49',
          400: '#EA4A5A',
          300: '#F97583',
          200: '#FDAEB7',
          100: '#FFDCE0',
          50: '#FFEEF0',
        },
        orange: {
          900: '#A04100',
          800: '#C24E00',
          700: '#D15704',
          600: '#E36209',
          500: '#F66A0A',
          400: '#FB8532',
          300: '#FFAB70',
          200: '#FFD1AC',
          100: '#FFEBDA',
          50: '#FFF8F2',
        },
        yellow: {
          900: '#735C0F',
          800: '#B08800',
          700: '#DBAB09',
          600: '#F9C513',
          500: '#FFD33D',
          400: '#FFDF5D',
          300: '#FFEA7F',
          200: '#FFF5B1',
          100: '#FFF9C6',
          50: '#FFFCEF',
        },
      },
      fontFamily: {
        sans: ['Inter', 'ui-sans-serif', 'system-ui', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', '"Noto Sans"', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"', '"Noto Color Emoji"'],
      },
    },
  },
  plugins: [
  ],
};
