export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      user: null,
      token: null,
      refresh: null,
    };
  },

  actions: {
    setAuth(payload) {
      if (payload) {
        const token = `Bearer ${payload.token}`;
        this.token = token;
        this.refresh = payload.refresh;
        this.user = payload.user;
        useCookie('token').value = token;
        useCookie('refresh').value = payload.refresh;
      } else {
        this.token = null;
        this.refresh = null;
        this.user = null;
        useCookie('token').value = null;
        useCookie('refresh').value = null;
      }
    },

    async login({ email, password }) {
      const response = await useApi.post('/public/users/login', {
        body: {
          email,
          password,
        },
      }).catch((error) => {
        console.error(error);
        if (error.response?.status === 403) {
          useToast.error(useT('bcd59'));
        } else if (error.response?.status === 498) {
          useToast.error(useT('bcd60'));
        } else {
          useToast.error(useT('bcd215'));
        }
      });

      if (response) {
        this.setAuth(response);

        return response.user;
      }

      return false;
    },

    logout() {
      this.setAuth(null);

      navigateTo('/login');
    },

    async fetchUser() {
      const response = await useApi.get('/users/me').catch(() => false);

      if (response) {
        this.user = response;

        return this.user;
      }

      return false;
    },

    async forgottenPassword({ email, lang }) {
      const response = await useApi.post('/public/users/forgotten-password', {
        body: {
          email,
          lang,
        },
      }).catch(() => {
        useToast.error(useT('bcd57'));
      });

      return response;
    },

    async newPassword({ email, password, token }) {
      const response = await useApi.post('/public/users/new-password', {
        body: {
          email,
          password,
          token,
        },
      }).catch(() => {
        useToast.error(useT('bcd56'));
      });

      return response;
    },
  },

  getters: {
    isLoggedIn(state) {
      return !!state.user;
    },
    hasRole(state) {
      return (role) => (Array.isArray(role) ? role.includes(state.user?.role) : state.user?.role === role);
    },
  },
});
