export default defineNuxtRouteMiddleware(async(to, from) => {
  if (process.client) {
    let isLoggedIn = useAuthStore().isLoggedIn;

    if (useCookie('token').value && !isLoggedIn) {
      await useAuthStore().fetchUser();
      isLoggedIn = useAuthStore().isLoggedIn;
    }

    if (to.meta.public && isLoggedIn) {
      return navigateTo('/');
    }
    if (!to.meta.public && !isLoggedIn) {
      return navigateTo('/login');
    }
  }
});
